.collate-background-PedagogicoPlan .ant-collapse-item {
    background-color: #1677FF;
    color: #ffffff;
  }
  
  .collate-background-PedagogicoPlan .ant-collapse-header {
    background-color: #1677FF;
    color: #ffffff;
  }
  
  .collate-background-PedagogicoPlan .ant-collapse-header:hover {
    background-color: #0E5ED1;
    color: #ffffff;
  }
  
  .collate-background-PedagogicoPlan  {
    background-color: #1677FF;
    color: #ffffff;
  }

  .collate-background-PedagogicoPlan span.ant-collapse-header-text  {
    color: #ffffff;
  }

  .collate-background-PedagogicoPlan .ant-collapse-arrow svg {
    fill: #ffffff;
  }

  /* Aplica fondo gris a la primera columna */
.pedagogical-plan-table .ant-table-tbody > tr > td:first-child {
    background-color: #f5f5f5; /* Gris claro */
  }
  
  /* Opcional: texto más oscuro para contraste */
  .pedagogical-plan-table .ant-table-tbody > tr > td:first-child span {
    color: #333;
  }

  
  /* PedagogicalPlanModal.css */
.pedagogical-plan-table {
  background-color: white !important;
}

.collate-background-PedagogicoPlan {
  background-color: white !important;
}

/* Asegurar que el contenido sea visible para html2canvas */
body > .ant-modal-wrap {
  overflow: visible !important;
}

.ant-modal {
  position: absolute;
}